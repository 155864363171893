import OfferDetailsBadge from './offerDetailsBadge';

const OfferDetails = ({ offer, activeSpecialOffer, moreDetailsModal }) => {

    const price = activeSpecialOffer ? offer.offerPrice : offer.monthlyCost;
    const [integerPart, decimalPart] = price.toFixed(2).split('.');

    return (
        <div className={`flex md:flex-row flex-col ${moreDetailsModal ? 'md:justify-evenly gap-6' : 'md:justify-between md:gap-20 gap-6'} items-center md:mt-0 mt-5 px-1`}>
            <div className='flex md:flex-col flex-row md:gap-6 gap-3'>
                <OfferDetailsBadge text="Download Speed">
                    <div className='md:text-3xl text-xl pt-4'>{offer.speed} Mbps</div>
                </OfferDetailsBadge>
                <OfferDetailsBadge text="Upload Speed">
                    <div className='md:text-3xl text-xl pt-4'>{offer.speed} Mbps</div>
                </OfferDetailsBadge>
            </div>

            <div className='flex flex-row md:gap-20 gap-3'>
                <OfferDetailsBadge text="Contract Length">
                    <div className="flex flex-row gap-2 md:py-10 pt-8 pb-2 items-end">
                        <span className='md:text-6xl text-2xl'>{offer.contractLength} </span>
                        <span className='md:text-2xl text-lg'>months</span>
                    </div>
                </OfferDetailsBadge>

                <OfferDetailsBadge text="Price">
                    <div className='flex flex-col gap-2 items-center md:pt-8 md:pb-4 pt-4 pb-2'>
                        <div className='flex flex-row gap-1 items-center'>
                            <div className='md:text-5xl text-2xl'>&pound;{integerPart}</div>
                            <div className='flex flex-col md:text-xl text-sm'>
                                <div>.{decimalPart}</div>
                                <div>/month</div>
                            </div>
                        </div>

                        {activeSpecialOffer ? (
                            <div className='md:text-sm text-xs font-normal'>&pound;{offer.monthlyCost.toFixed(2)}/month after {offer.offerLength} months</div>
                        ) : (
                            <div className='md:text-sm text-xs font-normal'>&pound;{offer.upFrontCost.toFixed(2)} Upfront Cost</div>
                        )}
                    </div>
                </OfferDetailsBadge>
            </div>
        </div>
    )
}

export default OfferDetails;