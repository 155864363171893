const FilterButton = ({ text, color, onClick }) => {
    return (
        <button
            className={`md:p-2 p-3 rounded-full font-bold transition-colors duration-300 focus:ring-0 bg-${color ? color : 'ms3-blue'} text-white hover:bg-ms3-pink text-md`}
            type="submit"
            onClick={onClick}>
            {text}
        </button>
    )
}

export default FilterButton;