import React from 'react';
import loaderImage from '../../../img/spiral1.png';

const Loader = ({ size }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75">
            <div className="flex flex-col items-center">
                <img
                    src={loaderImage}
                    alt="Loader"
                    className={`animate-spin-slow ${size === 'small' ? 'w-16 h-16' : 'w-32 h-32'}`} />
            </div>
        </div>
    );
}

export default Loader;
