import { FloatingLabel } from "flowbite-react";
import { useState } from "react";

const Input = ({ name, placeholder, shortPlaceholder, value, label, invalid, onChange, onKeyDown, disabled, error }) => {

    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);

    const handleFocus = () => {
        setCurrentPlaceholder(shortPlaceholder || placeholder);
    };

    const handleBlur = () => {
        setCurrentPlaceholder(value ? currentPlaceholder : placeholder);
    };

    const invalidMessage = invalid ? (error ? error : `${placeholder} is invalid`) : "";

    return (
        <div className="block w-full z-0 mt-5">
            {label && (
                <div className="text-lg mb-5 text-ms3-blue font-bold">{label}</div>
            )}

            <div className={`${invalid ? 'border-l-4 rounded-2xl border-ms3-pink' : ''}`}>
                <FloatingLabel
                    id={name}
                    disabled={disabled}
                    name={name}
                    variant="outlined"
                    label={currentPlaceholder}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={`md:text-lg z-0 text-md text-balance ${invalid ? 'caret-ms3-pink border-ms3-pink focus:border-ms3-pink peer-focus:text-ms3-pink' : 'caret-ms3-blue focus:border-ms3-blue peer-focus:text-ms3-blue'}`} />
            </div>

            <span className="text-ms3-pink font-bold ml-2">{invalidMessage}</span>
        </div>
    );
}

export default Input;