import { Accordion, AccordionContent, AccordionTitle, AccordionPanel, Checkbox, Label, Radio } from 'flowbite-react';
import { useState } from 'react';
import { contractLengthOptions, packageTypeOptions, speedOptions } from '../../../constants/constants';
import Slider from '../../common/slider/slider';
import FilterButton from '../../common/button/filterButton';
import { getMaxCost, getMinCost } from '../utils/offerUtils';

const FiltersAccordion = ({ providers, offers, filters, setFilters }) => {

    const [selectedOptions, setSelectedOptions] = useState({
        contractLength: [],
        packageType: [],
        serviceProvider: []
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        setFilters((previousFilters) => ({
            ...previousFilters,
            [name]: value,
        }));
    };

    const handleMonthlyCostChange = (value) => {
        setFilters((previousFilters) => ({
            ...previousFilters,
            monthlyCost: value,
        }));
    }

    const handleMultiSelect = (name) => (e) => {
        const { value, checked } = e.target;
        let updatedOptions = [...selectedOptions[name]];

        if (checked) {
            updatedOptions.push(value);
        } else {
            updatedOptions = updatedOptions.filter(option => option !== value);
        }

        setSelectedOptions({
            ...selectedOptions,
            [name]: updatedOptions,
        });

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: updatedOptions,
        }));
    };

    const clearFilters = () => {
        setSelectedOptions({
            contractLength: [],
            packageType: [],
            serviceProvider: []
        });
        setFilters({
            speed: "All",
            monthlyCost: getMaxCost(offers),
            contractLength: [],
            packageType: [],
            serviceProvider: [],
        });
    };

    const getLogoName = (provider) => {
        return "/providers/" +
            offers.filter((offer) => offer.serviceProvider === provider)[0].customerId +
            ".png";
    }

    return (
        <Accordion className="border-none hidden md:block">
            <AccordionPanel>
                <AccordionTitle className="py-2 px-5 font-bold text-lg bg-transparent hover:text-ms3-pink hover:bg-transparent focus:ring-0">
                    Filter Packages
                </AccordionTitle>

                <AccordionContent className="!border-none">
                    <div className='flex flex-col gap-3 bg-gray-100 py-5 px-7 border-1 border-gray-500 rounded-xl divide-y divide-solid'>

                        <div className='mb-5'>
                            <div className='text-xl font-bold mb-5 text-ms3-blue'>
                                Minimum Speed
                            </div>

                            <fieldset className="flex max-w-md flex-col gap-4">
                                {speedOptions.map(option => (
                                    <div className="flex items-center gap-2" key={option}>
                                        <Radio
                                            id={`speed-${option}`}
                                            name="speed"
                                            value={option}
                                            checked={filters.speed === option}
                                            onChange={handleFilterChange}
                                            className='text-ms3-blue focus:ring-0 cursor-pointer'
                                        />
                                        <Label htmlFor={`speed-${option}`} className='text-md cursor-pointer hover:text-ms3-pink'>{option === 'All' ? 'All' : `${option} Mbps`}</Label>
                                    </div>
                                ))}
                            </fieldset>
                        </div>

                        <div className='mb-5'>
                            <div className='text-xl font-bold mt-5 mb-5 text-ms3-blue'>
                                Maximum Cost
                            </div>

                            <Slider
                                min={getMinCost(offers)}
                                max={getMaxCost(offers)}
                                step={1}
                                value={filters.monthlyCost}
                                setValue={handleMonthlyCostChange}
                            />
                        </div>

                        <div className='mb-5'>
                            <div className='text-xl font-bold mt-5 mb-5 text-ms3-blue'>
                                Contract Length
                            </div>

                            <fieldset className="flex max-w-md flex-col gap-4 ">
                                {contractLengthOptions.map((option, index) => (
                                    <div className="flex items-center gap-2" key={index}>
                                        <Checkbox
                                            id={`contractLength.${index}`}
                                            name="contractLength"
                                            value={option}
                                            size="lg"
                                            checked={selectedOptions.contractLength.includes(option)}
                                            onChange={handleMultiSelect("contractLength")}
                                            className="text-ms3-blue ring-0 focus:ring-0 cursor-pointer bg-white"
                                        />
                                        <label htmlFor={`contractLength.${index}`} className="cursor-pointer hover:text-ms3-pink text-black">{option === "Any" ? "Any" : `${option} months`}</label>
                                    </div>
                                ))}
                            </fieldset>
                        </div>

                        <div className='mb-5'>
                            <div className='text-xl font-bold mt-5 mb-5 text-ms3-blue'>
                                Package Type
                            </div>

                            <fieldset className="flex max-w-md flex-col gap-4">
                                {packageTypeOptions.map((option, index) => (
                                    <div className="flex items-center gap-2" key={index}>
                                        <Checkbox
                                            id={`packageType.${index}`}
                                            name="packageType"
                                            value={option}
                                            checked={selectedOptions.packageType.includes(option)}
                                            onChange={handleMultiSelect("packageType")}
                                            className="text-ms3-blue focus:ring-0 cursor-pointer bg-white"
                                        />
                                        <label htmlFor={`packageType.${index}`} className="text-md cursor-pointer hover:text-ms3-pink">{option}</label>
                                    </div>
                                ))}
                            </fieldset>
                        </div>

                        <div className='mb-5'>
                            <div className='text-xl font-bold mt-5 mb-5 text-ms3-blue'>
                                Service Provider
                            </div>

                            <fieldset className="flex max-w-md flex-col gap-4">
                                {providers.map((provider, index) => (
                                    <div className="flex items-center gap-2" key={`provider.${index}`}>
                                        <Checkbox
                                            id={`provider.${index}`}
                                            name="serviceProvider"
                                            value={provider}
                                            checked={selectedOptions.serviceProvider.includes(provider)}
                                            onChange={handleMultiSelect("serviceProvider")}
                                            className='text-ms3-blue focus:ring-0 cursor-pointer bg-white'
                                        />
                                        <img src={getLogoName(provider)}
                                            onError={(e) => e.target.src = "/providers/default-logo.png"}
                                            alt="logo"
                                            className="h-6 sm:h-6 rounded-md" />
                                        <Label htmlFor={`provider.${index}`} className='text-md cursor-pointer hover:text-ms3-pink'>{provider}</Label>
                                    </div>
                                ))}
                            </fieldset>
                        </div>

                        <FilterButton color='ms3-pink' onClick={clearFilters} text="Clear Filters" />
                    </div>
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
    );
}

export default FiltersAccordion;