import {  PiNumberCircleOneFill, PiNumberCircleTwoFill, PiNumberCircleThreeFill } from "react-icons/pi";

const HowToOrder = () => {
    return (
        <div className="relative p-2 border border-gray-300 rounded-2xl w-full">
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-3 px-2 bg-gray-200 text-gray-700 text-md rounded-full w-48 flex flex-col items-center font-bold">
                How to Order
            </div>
            <div className="md:flex flex-row items-center justify-between gap-36 p-10">
                <div className="flex flex-row gap-5">
                    <PiNumberCircleOneFill className="text-5xl" />
                    <div className='text-md'>Click 'Purchase Now' to confirm your details</div>
                </div>

                <div className="flex flex-row gap-5">
                    <PiNumberCircleTwoFill className="md:text-5xl text-3xl" />
                    <div className='text-md'>Choose your install date</div>
                </div>

                <div className="flex flex-row gap-5">
                    <PiNumberCircleThreeFill className="text-5xl" />
                    <div className='text-md'>Our Engineer visits to get you connected</div>
                </div>
            </div>
        </div>
    )
}

export default HowToOrder;