import { Modal } from "flowbite-react";
import { useState } from "react";
import HowToOrder from "./howToOrder";
import Contract from "./contract";
import Ms3Button from "../../common/button/button";
import OfferDetails from "../offer/offerDetails";
import OfferOptions from "../offer/offerOptions";
import Provider from "./provider";

const OfferDetailsModal = ({ openModal, onClose, activeSpecialOffer, offer, purchase }) => {

    const [activeTab, setActiveTab] = useState('contract');

    const onSubmit = () => {
        onClose();
        purchase(offer);
    }

    return (
        <Modal size="7xl" show={openModal} onClose={onClose} dismissible>
            <Modal.Header>
                <div className='text-2xl font-bold'>{`${offer.serviceProvider} - ${offer.name}`}</div>
            </Modal.Header>
            <Modal.Body>
                <div className="md:px-5 px-1">
                    <div className="mb-10">
                        <OfferDetails offer={offer} activeSpecialOffer={activeSpecialOffer} moreDetailsModal={true} />
                    </div>

                    {offer.offerOptionsBadges && offer.offerOptionsBadges.length > 0 && (
                        <div className="mb-10">
                            <OfferOptions offer={offer} moreDetailsModal={true} />
                        </div>
                    )}

                    <HowToOrder />

                    <div className="md:p-10 p-7 border-2 border-purple-100 rounded-2xl mt-10 mb-5 shadow-ms3-blue-xl">
                        <div className="flex flex-row md:justify-start justify-evenly md:gap-10 mb-10">
                            <div key="contract"
                                onClick={() => setActiveTab('contract')}
                                className={`md:text-xl text-lg text-gray-500 font-bold cursor-pointer p-3 ${activeTab === 'contract' ? 'text-ms3-ultrapink border-b-4 border-ms3-ultrapink' : ''}`}>
                                Contract
                            </div>

                            <div key="provider"
                                onClick={() => setActiveTab('provider')}
                                className={`md:text-xl text-lg text-gray-500 font-bold cursor-pointer p-3 ${activeTab === 'provider' ? 'text-ms3-ultrapink border-b-4 border-ms3-ultrapink' : ''}`}>
                                Provider
                            </div>
                        </div>

                        {activeTab === 'contract' && (
                            <Contract offer={offer} activeSpecialOffer={activeSpecialOffer} />
                        )}

                        {activeTab === 'provider' && (
                            <Provider offer={offer} />
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex justify-center w-full">
                    <Ms3Button onClick={onSubmit} text="Purchase Now" />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default OfferDetailsModal;