import { Dropdown } from "flowbite-react";

const Ms3Dropdown = ({ label, options, additionalOptions, value, placeholder, selectOption, invalid }) => {
    return (
        <div className="block w-full z-1 mt-5">
            {label && (
                <div className="text-lg mb-5 text-ms3-blue font-bold">{label}</div>
            )}

            <div className={`border ${invalid ? 'border-l-4 rounded-2xl border-ms3-pink mb-2' : 'border-gray-300'} focus:border-ms3-blue rounded-lg p-3 relative`}>
                <Dropdown label="" placement="bottom" renderTrigger={() =>
                    <div className="flex justify-between items-center w-full">
                        <span className={`text-lg ${value ? 'text-black' : 'text-gray-500'}`}>{value ? value : `Select ${placeholder}`}</span>
                        <span>▼</span>
                    </div>}>

                    <div className="max-h-60 overflow-y-auto">
                        {options.map((option) => (
                            <Dropdown.Item
                                key={option}
                                onClick={() => selectOption(option)}
                                className='text-md text-left focus:text-ms3-pink focus:bg-transparent hover:bg-transparent'>
                                {option}
                            </Dropdown.Item>
                        ))}

                        {additionalOptions && (
                            <>
                                <Dropdown.Divider />

                                {additionalOptions.map((option) => (
                                    <Dropdown.Item
                                        key={option}
                                        onClick={() => selectOption(option)}
                                        className='text-md text-left focus:text-ms3-pink focus:bg-transparent hover:bg-transparent'>
                                        {option}
                                    </Dropdown.Item>
                                ))}
                            </>
                        )}
                    </div>
                </Dropdown>
            </div>

            <span className={`${invalid ? 'text-ms3-pink' : 'text-white'} font-bold ml-2`}>{placeholder} is invalid</span>
        </div>
    );
}

export default Ms3Dropdown;