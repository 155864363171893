import sanitizeHtml from 'sanitize-html';
import TrustScore from '../score/trustscore';

const Provider = ({ offer }) => {

    const getLogoName = () => {
        return "/providers/" +
            offer.customerId +
            ".png";
    }

    return (
        <>
            <div className="flex flex-row justify-start gap-5 items-center mb-5">
                <img src={getLogoName()}
                    onError={(e) => e.target.src = "/providers/default-logo.png"}
                    alt="logo"
                    className="md:w-1/6 w-1/3 rounded-full border-4 border-white" />

                <div>
                    <span className="md:text-xl text-md font-bold">{offer.serviceProvider}</span>

                    {offer.trustPilotScore && offer.trustPilotScore.businessUnitId && (
                        <div className='mt-2'>
                            <TrustScore offer={offer} moreDetailsModal={true} />
                        </div>
                    )}
                </div>
            </div>

            <div className="text-md md:px-5 py-2" dangerouslySetInnerHTML={{ __html: sanitizeHtml(offer.details) }} />
        </>
    )
}

export default Provider;