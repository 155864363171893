import { Badge, Card } from 'flowbite-react';

import CompletionModal from './completionModal';
import { FormatDate } from "../../util/date/dateUtils";
import Loader from '../common/loader/loader';
import Ms3Button from '../common/button/button';
import { useApiClient } from '../../api/useApiClient';
import { useState } from 'react';

const Confirmation = ({ order }) => {

    const { callApi, loading, error, errorDetails } = useApiClient();
    const [openModal, setOpenModal] = useState(false);
    const [orderReference, setOrderReference] = useState("");

    const confirm = async () => {
        const response = await callApi('placeOrder', buildOrderRequest(order));
        setOrderReference(response ? response.orderNumber : "");
        setOpenModal(true);
    }

    const buildOrderRequest = (order) => {
        return {
            "installationDetails": order.installationDetails, 
            "offer": order.selectedOffer,
            "customerDetails": order.customerDetails,
            "address": order.address,
            "bpPayloadCookie": order.bpPayloadCookie
        }
    }

    return (
        <>
            {loading && <Loader />}

            <div className="md:my-20 my-5 flex flex-col items-center md:mx-0 mx-3">
                <Card className="md:max-w-5xl max-w-md md:p-10 p-3 shadow-ms3-blue-xl border-0">
                    <div className="flex flex-col">
                        <div className='text-xl text-ms3-blue font-bold mb-10'>
                            Review and Confirm
                        </div>

                        <div className="p-10 mb-10 rounded-lg shadow-ms3-blue-xl bg-gray-50 divide-y divide-solid">
                            <div className='text-lg font-bold mb-5 md:text-left text-center'>
                                Offer Details
                            </div>

                            <div className="flex md:flex-row flex-col md:gap-36 gap-5 pt-5 text-center">
                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Offer Name
                                    </div>
                                    <div>
                                        {order && order.selectedOffer ? `${order.selectedOffer.name}` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Contract Length
                                    </div>
                                    <div>
                                        {order && order.selectedOffer ? `${order.selectedOffer.contractLength} months` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Monthly Cost
                                    </div>
                                    <div>
                                        &pound;{order && order.selectedOffer ? `${order.selectedOffer.monthlyCost}` : "-"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-10 mb-10 rounded-lg shadow-ms3-blue-xl bg-gray-50 divide-y divide-solid">
                            <div className='text-lg font-bold mb-5 md:text-left text-center'>
                                Customer Details
                            </div>

                            <div className="flex md:flex-row flex-col md:gap-36 gap-5 pt-5 text-center mb-5">
                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Name
                                    </div>
                                    <div>
                                        {order && order.customerDetails && (order.customerDetails.firstName || order.customerDetails.lastName)
                                            ? `${order.customerDetails.firstName} ${order.customerDetails.lastName}`
                                            : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Phone Number
                                    </div>
                                    <div>
                                        {order && order.customerDetails && order.customerDetails.phoneNumber ? `${order.customerDetails.phoneNumber}` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Email
                                    </div>
                                    <div>
                                        {order && order.customerDetails && order.customerDetails.email ? `${order.customerDetails.email}` : "-"}
                                    </div>
                                </div>
                            </div>

                            <div className="md:flex flex-row gap-10 pt-5 justify-center text-center mb-5">
                                <div>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Address
                                    </div>
                                    <div>
                                        {order && order.address && order.address.fullAddress ? `${order.address.fullAddress}` : "-"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-10 mb-10 rounded-lg shadow-ms3-blue-xl bg-gray-50 divide-y divide-solid">
                            <div className='text-lg font-bold mb-5 md:text-left text-center'>
                                Installation Details
                            </div>

                            <div className="flex md:flex-row flex-col md:gap-36 gap-5 pt-5 text-center mb-5">
                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Installation Date
                                    </div>
                                    <div className='flex flex-row gap-2 items-center justify-center'>
                                        {order.installationDetails.timeSlot && (
                                            <Badge className='p-3 font-bold text-xs bg-ms3-blue text-white'>
                                                {order.installationDetails.timeSlot.toUpperCase()}
                                            </Badge>
                                        )}
                                        {order && FormatDate(order.installationDetails.customerRequestedDate)}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        On Site Location
                                    </div>
                                    <div>
                                        {order && order.installationDetails && order.installationDetails.onSiteLocation ? `${order.installationDetails.onSiteLocation}` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Access Instructions
                                    </div>
                                    <div>
                                        {order && order.installationDetails && order.installationDetails.accessInstructions ? `${order.installationDetails.accessInstructions}` : "-"}
                                    </div>
                                </div>
                            </div>

                            <div className="flex md:flex-row flex-col md:gap-36 gap-5 pt-5 text-center">
                                <div className='md:w-1/2'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Additional Information
                                    </div>
                                    <div>
                                        {order && order.installationDetails && order.installationDetails.specialAccessInstructions ? `${order.installationDetails.specialAccessInstructions}` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/2'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Where did you hear about MS3 Networks?
                                    </div>
                                    <div>
                                        {order && order.installationDetails && order.installationDetails.leadReferralSource ? `${order.installationDetails.leadReferralSource}` : "-"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="p-10 mb-10 rounded-lg shadow-ms3-blue-xl bg-gray-50 divide-y divide-solid">
                            <div className='text-lg font-bold mb-5 md:text-left text-center'>
                                Payment Details
                            </div>

                            <div className="flex md:flex-row flex-col md:gap-36 gap-5 pt-5 text-center">
                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Account Holder
                                    </div>
                                    <div>
                                        {order && order.paymentDetails && order.paymentDetails.accountHolder ? `${order.paymentDetails.accountHolder}` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Sort Code
                                    </div>
                                    <div>
                                        {order && order.paymentDetails && order.paymentDetails.sortCode ? `${order.paymentDetails.sortCode}` : "-"}
                                    </div>
                                </div>

                                <div className='md:w-1/3'>
                                    <div className='text-md text-ms3-blue font-bold mb-2'>
                                        Account
                                    </div>
                                    <div>
                                        {order && order.paymentDetails && order.paymentDetails.account ? `${order.paymentDetails.account}` : "-"}
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <Ms3Button onClick={confirm} text="Confirm" disabled={loading} />
                    </div>

                    <CompletionModal error={error} errorDetails={errorDetails} orderReference={orderReference} openModal={openModal} onClose={() => setOpenModal(false)} />

                </Card>
            </div>
        </>
    )
}

export default Confirmation;
