const OfferOptionsBadge = ({ text, children }) => {
    return (
        <div>
            <div className="flex flex-row gap-1 items-center border bg-gray-200 rounded-full px-3 py-1 md:text-sm text-xs font-bold">
                {children}
                <div>{text}</div>
            </div>
        </div>
    )

}

export default OfferOptionsBadge;