const Ms3Button = ({ text, disabled, onClick }) => {
    return (
        <button
            className="px-8 py-3 rounded-full font-bold transition-colors duration-300 focus:ring-0 bg-ms3-ultrapink text-white hover:bg-ms3-pink md:text-xl text-md"
            type="submit"
            disabled={disabled}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default Ms3Button;