import ReactPlayer from "react-player";

const InstallationVideo = () => {
    return (
        <div className='flex'>
            <ReactPlayer url='https://player.vimeo.com/video/987028976?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                playing={false}
                controls={true}
            />
        </div>
    );
}

export default InstallationVideo;