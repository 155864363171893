import { useEffect, useState } from 'react';
import Toolbar from "../src/components/toolbar/toolbar";
import OfferList from './components/offers/list/offerList';
import Steps from './components/steps/steps';
import Loader from './components/common/loader/loader';
import CustomerDetails from './components/customerDetails/customerDetails';
import Installation from "./components/installation/installation";
import Confirmation from "./components/confirmation/confirmation";
import AddressChecker from "./components/address/addressChecker";
import Payment from './components/payment/payment';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useApiClient } from './api/useApiClient';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';
import RedirectOnRefresh from './components/common/redirect/redirect';
import OfferRedirect from './components/offers/redirect/offerRedirect';

const App = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { callApi, loading } = useApiClient();
  const [cookies] = useCookies(['bp_payload']);
  const [loadingPage, setLoadingPage] = useState(false);
  const [bpPayloadCookie, setBpPayloadCookie] = useState();

  const [steps, setSteps] = useState([
    {
      tabName: "Address",
      nextTab: "Offers",
      completed: false
    },
    {
      tabName: "Offers",
      nextTab: "Details",
      completed: false
    },
    {
      tabName: "Details",
      nextTab: "Installation",
      completed: false
    },
    {
      tabName: "Installation",
      nextTab: "Confirmation",
      completed: false
    },
    {
      tabName: "Confirmation",
      nextTab: null,
      completed: false
    }
  ]);

  const [activeTab, setActiveTab] = useState('Address');

  const [address, setAddress] = useState({
    postcode: "",
    uprn: "",
    fullAddress: "",
    buildingNumber: "",
    street: "",
    provider: "",
    agentReference: "",
    searchList: []
  });

  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState();

  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    agreement: false
  });

  const [availableAppointments, setAvailableAppointments] = useState([]);

  const [installationDetails, setInstallationDetails] = useState({
    customerRequestedDate: "",
    timeSlot: "AM",
    onSiteLocation: "",
    accessInstructions: "",
    specialAccessInstructions: "",
    referralOption: null,
    leadReferralSource: null,
    appointmentReference: ""
  })

  const [referralSource, setReferralSource] = useState();
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const closeRedirectModal = () => {
    setShowRedirectModal(false);
  }

  const [paymentDetails, setPaymentDetails] = useState({
    accountHolder: "",
    sortCode: "",
    account: ""
  });

  const [order, setOrder] = useState({
    address,
    selectedOffer,
    customerDetails,
    installationDetails,
    paymentDetails,
    bpPayloadCookie
  })

  useEffect(() => {
    const currentPath = location.pathname.replace('/', '');
    if (currentPath) {
      setActiveTab(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    } else {
      setActiveTab("Address");
    }

    var cookieValue = cookies.bp_payload;
    if (cookieValue) {
      setBpPayloadCookie(cookieValue);
    }
  }, [location, cookies.bp_payload]);

  const updateAddress = (selectedAddress) => {
    setAddress(selectedAddress);
    resetCurrentStep();
  }

  const findDeals = async () => {
    var response = await callApi('getOffers');
    setOffers(response);

    nextStep();
  }

  const purchase = async (offer) => {
    setSelectedOffer(offer);

    if (offer.externalOrderFlow) {
      setShowRedirectModal(true);
    } else {
      nextStep(offer);
    }
  }

  const redirectToExternalFlow = async (referralSourceValue) => {
    setShowRedirectModal(false);

    await callApi('saveMetadata', {
      uprn: address.uprn,
      offer: selectedOffer,
      currentProvider: address.provider,
      agentReference: address.agentReference,
      leadReferralSource: referralSourceValue,
      bpPayload: bpPayloadCookie
    });

    redirectToExternalUrl(selectedOffer);
  }

  const buildExternalOrderFlowUrl = (offer) => {
    const url = new URL(offer.externalOrderFlow.url);

    url.searchParams.set("uprn", address.uprn);
    url.searchParams.set("postcode", address.postcode);
    if (offer.externalOrderFlow.packageId) {
      url.searchParams.set("packageId", offer.externalOrderFlow.packageId);
    }

    return url.toString();
  }

  const redirectToExternalUrl = (offer) => {
    setLoadingPage(true);
    const url = buildExternalOrderFlowUrl(offer);
    window.location.href = url;
  }

  const goToInstallation = async () => {
    const response = await callApi('getAvailableAppointments', {
      "phoneNumber": customerDetails.phoneNumber,
      "uprn": address.uprn.toString(),
      "appointmentDate": dayjs(new Date()).format('YYYY-MM-DD')
    });

    setAvailableAppointments(response ? response : []);

    if (response && response[0]) {
      setInstallationDetails({
        ...installationDetails,
        customerRequestedDate: response[0].appointmentDate,
        timeSlot: response[0].appointmentSlot,
        appointmentReference: response[0].appointmentReference
      })
    }

    nextStep()
  }

  const updateCustomerDetails = (details) => {
    setCustomerDetails(details);
    resetCurrentStep();
  }

  const updateInstallationDetails = (details) => {
    setInstallationDetails(details);

    setOrder({
      ...order,
      installationDetails: details,
    });
  }

  const updatePaymentDetails = (details) => {
    setPaymentDetails(details);

    setOrder({
      ...order,
      paymentDetails: details,
    });
  }

  const nextStep = (offer) => {
    const currentStepIndex = steps.findIndex(step => step.tabName === activeTab);

    if (currentStepIndex !== -1) {
      const updatedSteps = [...steps];
      updatedSteps[currentStepIndex].completed = true;
      const nextTab = updatedSteps[currentStepIndex].nextTab;

      setSteps(updatedSteps);

      if (nextTab) {
        setActiveTab(nextTab);
        navigate(`/${nextTab}`);
      }

      setOrder({
        address,
        "selectedOffer": offer ? offer : selectedOffer,
        customerDetails,
        installationDetails,
        paymentDetails,
        bpPayloadCookie
      });
    }
  }

  const resetCurrentStep = () => {
    const currentStepIndex = steps.findIndex(step => step.tabName === activeTab);

    const updatedSteps = [...steps];
    updatedSteps[currentStepIndex].completed = false;
    setSteps(updatedSteps);
  }

  return (
    <div className="flex flex-col h-screen font-inter">
      <Toolbar />

      {activeTab && (
        <Steps steps={steps} {...{ activeTab, setActiveTab }} />
      )}

      <div className="">
        <RedirectOnRefresh />

        <Routes>
          <Route exact path="*" element={<AddressChecker address={address} setAddress={setAddress} updateAddress={updateAddress} onSubmit={findDeals} />} />
          <Route path="/address" element={<AddressChecker address={address} setAddress={setAddress} updateAddress={updateAddress} onSubmit={findDeals} />} />
          <Route path="/offers" element={<OfferList address={address} offers={offers} purchase={purchase} />} />
          <Route path="/details" element={<CustomerDetails customerDetails={customerDetails} updateCustomerDetails={updateCustomerDetails} stepCompleted={steps[2].completed} onSubmit={goToInstallation} />} />
          <Route path="/installation" element={<Installation availableAppointments={availableAppointments} installationDetails={installationDetails} updateInstallationDetails={updateInstallationDetails} onSubmit={nextStep} uprn={address.uprn} />} />
          <Route path="/payment" element={<Payment provider={selectedOffer ? selectedOffer.serviceProvider : ""} paymentDetails={paymentDetails} updatePaymentDetails={updatePaymentDetails} onSubmit={nextStep} />} />
          <Route path="/confirmation" element={<Confirmation order={order} />} />
        </Routes>

        <OfferRedirect showModal={showRedirectModal} offer={selectedOffer} referralSource={referralSource} setReferralSource={setReferralSource} closeModal={closeRedirectModal} onSubmit={redirectToExternalFlow} />

        {(loading || loadingPage) && <Loader />}
      </div>
    </div>
  );
}

export default App;
