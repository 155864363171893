import { FormatDate } from "../../../util/date/dateUtils";
import { WiTime3 } from "react-icons/wi";

const SpecialOfferBadge = ({ show, offer }) => {

    return (
        <>
            {show && (
                <div className="absolute md:left-3/4 left-1/2 transform -translate-x-1/2 -top-2 p-1 bg-ms3-pink text-white md:text-sm text-xs rounded-full md:w-1/4 w-5/6 flex flex-row gap-1 items-center justify-center font-bold">

                    {offer.offerExpirationDate ? (
                        <>
                            <div>Special Offer Ends {FormatDate(offer.offerExpirationDate)}</div>
                            <WiTime3 className='text-xl font-bold' />
                        </>
                    ) : (
                        <div>Special Offer</div>
                    )}
                </div>
            )}
        </>
    )
}

export default SpecialOfferBadge;