export const providers = [
  'BT',
  'Sky Broadband',
  'EE',
  'TalkTalk',
  'Virgin Media',
  'Connexin',
  'Grain',
  'Vodafone',
  'Plusnet',
  'Three',
  'NOW TV',
  'KCOM',
  'Utility Warehouse',
  'Link Broadband',
  'Zone Broadband',
  'Infinics',
  'Fiber Zone',
  'MTH Networks',
  'Eclipse Broadband',
  'Home Telecom',
  'Hull Fibre',
  'Direct Save Telecom',
  'Octaplus',
  'Open Fibre',
  'Squirrel',
  'Yayzi Broadband',
  'Fusion Fibre Group',
  'Fleur Telecom'
];

export const unknownProviders = [
  "I don't have broadband",
  "I'm not sure",
  "My provider isn't listed",
];

export const contractLengthOptions = ['6', '9', '12', '18', '24'];
export const speedOptions = [
  'All',
  '100 - 499',
  '500 - 899',
  '900 - 1000'
];

export const packageTypeOptions = ['Broadband', 'Broadband & Phone', 'Broadband, Phone & TV'];

export const sortOptions = [
  "Promoted Packages",
  "Price (Lowest to Highest)",
  "Price (Highest to Lowest)",
  "Trustpilot Score (Highest to Lowest)",
  "Trustpilot Score (Lowest to Highest)"
];

export const referralsList = [
  'Facebook',
  'Instagram',
  'Twitter',
  'LinkedIn',
  'TikTok',
  'Youtube',
  'Hull Live',
  'ITVX',
  '4oD',
  'Digital Radio',
  'Letter / Flyer',
  'Local Event',
  'Search Engine (Google, Bing, etc)',
  'Billboard',
  'Referred by a friend / family member',
  'Installation works on my street',
  'Other, please state',
];

export const agreementText =
  'Register for exclusive offers and news via email';

export const installationProcessDetails =
  'The installation process will be undertaken by trained engineers who will need access to your property to determine the best way to get you connected to our ultrafast full fibre network. Whilst the required work is small and unintrusive it is something we want to make you aware of depending on how the infrastructure has been delivered to your street.';

export const undergroundInfrastructureDetails =
  'If your street has infrastructure supplied underground, once you have completed your order a desktop survey of your property will be conducted. This will determine the best route to channel underground cables to the front of your property. All works are completed to the highest of standards and everything will be put back just how it was upon completion.';

export const overheadInfrastructureDetails =
  'If your street has infrastructure supplied overhead via a telegraph pole no ground works will be required just some external cabling to be connected to your property. One of our highly skilled install engineers will visit your property at the selected date below to get you connected to our ultrafast full fibre network.';

export const installationNote =
  'If you have already had an installation from MS3 Networks and are switching to a new Internet Provider on our network, then a further installation is not required – the hardware to your property will provide the network access required and a new router will be all you need from your new Internet Provider. Please continue to choose an installation date which will be cancelled at a later step to continue. If you have any questions regarding the installation process, we will be happy to help. Please call us on 01482 221721 or email us info@ms-3.co.uk.';

  export const ms3PhoneNumber = "01482 736129"