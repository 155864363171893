import { Modal } from "flowbite-react"
import { referralsList } from '../../../constants/constants';
import Ms3Dropdown from "../../common/dropdown/dropdown";
import Input from "../../common/input/input";
import { useState } from "react";
import Ms3Button from "../../common/button/button";
import { validateField } from "../../../util/validation/validation";

const OfferRedirect = ({ offer, showModal, referralSource, setReferralSource, closeModal, onSubmit }) => {

    const [referralOption, setReferralOption] = useState();

    const [errors, setErrors] = useState({
        referralOption: false,
        referralSource: false,
    });

    const validateFields = (name, value) => {
        const valid = validateField(name, value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !valid,
        }));

        return valid;
    };

    const setOption = (option) => {
        const referralValue = (option === 'Other, please state' ? "" : option);

        setReferralSource(referralValue);
        setReferralOption(option);

        const valid = validateFields('referralOption', option);

        if (valid && referralValue) {
            onSubmit(referralValue);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateFields('referralOption', referralOption) && validateFields('referralSource', referralSource)) {
            onSubmit(referralSource);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setReferralSource(value);

        validateFields(name, value);
    };

    const getLogoName = () => {
        return "/providers/" +
            (offer ? offer.customerId : "") +
            ".png";
    }

    const onClose = () => {
        setReferralOption();
        setReferralSource();
        closeModal();
    }

    return (
        <>
            <Modal size="lg" show={showModal} onClose={onClose} dismissible>
                <Modal.Header className="bg-gradient-to-r from-ms3-pink via-ms3-purple to-ms3-blue">
                    <div className="text-white flex flex-col gap-2 items-center">
                        <div className="text-2xl font-bold">
                            You are being redirected to
                        </div>

                        <div className="flex flex-row gap-5 items-center justify-center">
                            <img src={getLogoName()}
                                onError={(e) => e.target.src = "/providers/default-logo.png"}
                                alt="logo"
                                className="w-1/4 rounded-full border-4 border-white" />
                            <div className="text-3xl font-bold">
                                {offer ? offer.serviceProvider : ""}
                            </div>
                        </div>

                        <div className="text-2xl font-bold">
                            to complete your order
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body className="overflow-visible flex flex-col items-center">
                    <div className="text-ms3-ultrapink text-3xl font-bold">
                        Before you go
                    </div>

                    <div className="text-lg mt-2">
                        Where did you hear about MS3?
                    </div>

                    <Ms3Dropdown
                        options={referralsList}
                        value={referralOption}
                        selectOption={setOption}
                        placeholder=""
                        invalid={errors.referralOption}
                    />

                    {referralOption === 'Other, please state' && (
                        <Input
                            name="referralSource"
                            label=""
                            placeholder="Please state where you heard about us"
                            value={referralSource}
                            error="Value is invalid"
                            onChange={handleChange}
                            invalid={errors.referralSource}
                        />
                    )}

                    {referralOption !== 'Other, please state' && (
                        <div className="text-md font-bold">
                            Select an option to proceed
                        </div>
                    )}

                    {referralOption === 'Other, please state' && (
                        <div className="flex justify-center">
                            <Ms3Button text="Proceed" onClick={handleSubmit} />
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default OfferRedirect;