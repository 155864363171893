const OfferDetailsBadge = ({ text, children }) => {
    return (
        <div className="relative py-1 border border-gray-300 rounded-2xl md:w-64 w-[12rem]">
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-3 
                            bg-gray-200 text-gray-700 md:text-md text-sm rounded-full 
                            md:w-48 w-[9rem] flex flex-col items-center font-bold">
                {text}
            </div>
            <div className="items-center flex flex-col font-bold">
                {children}
            </div>
        </div>
    );
};

export default OfferDetailsBadge;
