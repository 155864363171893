import { useState } from 'react';
import apiConfig from "../api/apiConfig";

export const useApiClient = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorDetails, setErrorDetails] = useState([]);

    const callApi = async (endpoint, request = null, param = null) => {
        setLoading(true);
        setError(null);
        setData(null);

        try {
            const { url, method } = param
                ? apiConfig[endpoint](param)
                : apiConfig[endpoint];

            const config = {
                method,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            if (method === 'POST' || method === 'PUT') {
                config.body = JSON.stringify(request);
            }

            const response = await fetch(url, config);
            const responseData = await response.json();

            if (!response.ok) {
                setErrorDetails(responseData.PortalErrors);
                throw new Error(`${response.status} - ${response.statusText}`);
            }

            setData(responseData);
            return responseData;
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return { callApi, loading, error, errorDetails, data };
};
