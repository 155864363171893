const GhostButton = ({ text, disabled, hidden, onClick }) => {
    return (
        <button
            className="text-md font-bold text-gray-900 hover:bg-gray-100 transition-colors duration-300 rounded-full px-4 py-2"
            type="submit"
            hidden={hidden}
            disabled={disabled}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default GhostButton;