const Contract = ({ offer, activeSpecialOffer }) => {
    return (
        <div className="divide-y-2 divide-solid text-md font-bold">
            {activeSpecialOffer && offer.offerLength > 0 && (
                <div>
                    <div className="flex flex-row justify-between md:px-5 pt-5 pb-2">
                        <div>Offer Price</div>
                        <div >&pound;{offer.offerPrice.toFixed(2)}/month</div>
                    </div>

                    <div className="flex flex-row justify-between md:px-5 pb-5 pt-2">
                        <div>Offer Length</div>
                        <div>First {offer.offerLength} Months</div>
                    </div>
                </div>
            )}

            <div>
                <div className="flex flex-row justify-between md:px-5 pt-5 pb-2">
                    <div>Standard Monthly Cost</div>
                    <div>&pound;{offer.monthlyCost.toFixed(2)}/month</div>
                </div>

                <div className="flex flex-row justify-between md:px-5 pb-5 pt-2">
                    <div>Contract Length</div>
                    {activeSpecialOffer && offer.offerLength > 0 ? (
                        <div>Last {offer.contractLength - offer.offerLength} Months</div>
                    ) : (
                        <div>{offer.contractLength} Months</div>
                    )}
                </div>
            </div>

            <div>
                <div className="flex flex-row justify-between md:px-5 pt-5 pb-2">
                    <div>Installation</div>
                    <div>&pound;{offer.installationCost ? offer.installationCost.toFixed(2) : '0'}</div>
                </div>

                <div className="flex flex-row justify-between md:px-5 py-2">
                    <div>Equipment</div>
                    <div>&pound;{offer.equipmentCost ? offer.equipmentCost.toFixed(2) : '0'}</div>
                </div>

                <div className="flex flex-row justify-between md:px-5 py-2">
                    <div>Delivery</div>
                    <div>&pound;{offer.deliveryCost ? offer.deliveryCost.toFixed(2) : '0'}</div>
                </div>

                <div className="flex flex-row justify-between md:px-5 pb-5 pt-2">
                    <div>Activation Fee</div>
                    <div>&pound;{offer.activationFee ? offer.activationFee.toFixed(2) : '0'}</div>
                </div>
            </div>

            <div>
                <div className="flex flex-row justify-between md:px-5 pt-5 pb-2">
                    <div>Total Contract Length</div>
                    <div>{offer.contractLength} Months</div>
                </div>

                <div className="flex flex-row justify-between md:px-5 py-2">
                    <div>Total Contract Cost</div>
                    <div>&pound;{offer.totalCost.toFixed(2)}</div>
                </div>
            </div>
        </div>
    );
}

export default Contract;