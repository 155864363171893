import TrustScore from "../score/trustscore";

const OfferProviderDetails = ({ offer }) => {

    const getLogoName = () => {
        return "/providers/" +
            offer.customerId +
            ".png";
    }

    return (
        <div className={`md:w-1/6 flex md:flex-col flex-row gap-4 items-center justify-start py-3 md:px-7 px-3 
                         md:rounded-l-lg rounded-t-lg text-center break-words font-bold md:rounded-r-none 
                         ${offer.isPromoted ? 'bg-gradient-to-br from-ms3-pink to-ms3-purple' : 'bg-gray-300'}`}
        >
            <img src={getLogoName()}
                onError={(e) => e.target.src = "/providers/default-logo.png"}
                alt="logo"
                className="md:w-3/4 w-1/4 rounded-full border-4 border-white" />

            <div className="flex md:flex-col flex-row gap-5 items-center">
                <div className="flex flex-col md:gap-3 gap-1">
                    <span className={`${offer.isPromoted ? 'text-white' : 'text-black'} md:text-lg text-md`}>{offer.serviceProvider}</span>
                    <span className={`${offer.isPromoted ? 'text-white' : 'text-black'} md:text-md text-sm`}>{offer.name}</span>
                </div>

                <div className="md:w-full w-1/2">
                    {offer.trustPilotScore && offer.trustPilotScore.businessUnitId && (
                        <TrustScore offer={offer} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default OfferProviderDetails;