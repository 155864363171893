import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectOnRefresh = () => {
    const navigate = useNavigate();

    const buildUrl = () => {
        const params = new URLSearchParams(window.location.search);
        const postcode = params.get('postcode');
        const agentReference = params.get('agentReference');

        let url = "/?";
        if (postcode) {
            url += `postcode=${postcode}`;
        }
        if (agentReference) {
            url += `&agentReference=${agentReference}`;
        }
        return url;
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.setItem('isRefreshed', 'true');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        if (sessionStorage.getItem('isRefreshed') === 'true') {
            sessionStorage.removeItem('isRefreshed');
            navigate(buildUrl());
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [navigate]);

    return null;
};

export default RedirectOnRefresh;
