const WEBSITE_BASE_URL = process.env.REACT_APP_WEBSITE_BASE_URL;

const Toolbar = () => {

    const buildUrl = () => {
        return WEBSITE_BASE_URL + "/";
    }

    return (
        <a className='border-b-2 border-ms3-pink mt-3' href={buildUrl()}>
            <img src="/ms3.png" className="md:ml-16 ml-5 mb-3 md:h-16 h-5" alt="MS3" />
        </a>
    )
}

export default Toolbar;