import { referralsList } from "../../constants/constants";

export const validateField = (name, value) => {
    switch (name) {
        case 'postcode':
            return validatePostcode(value);
        case 'provider':
            return validateProvider(value);
        case 'uprn':
            return validateNumeric(value);
        case 'fullAddress':
            return validateAddress(value);
        case 'email':
            return validateEmail(value);
        case 'firstName':
        case 'lastName':
            return validateAlphabetic(value);
        case 'phoneNumber':
            return validatePhone(value);
        case 'accountHolder':
            return validateAlphabetic(value);
        case 'sortCode':
            return validateSortCode(value);
        case 'account':
            return validateAccountNumber(value);
        case 'onSiteLocation':
            return validateInstructions(value);
        case 'agentReference':
            return validateAlphaNumeric(value);
        case 'referralSource':
        case 'leadReferralSource':
            return validateReferral(value);
        case 'referralOption':
            return validateReferralOption(value);
        default:
            return true;
    }
}

const validatePhone = (phoneNumber) => {
    if (!phoneNumber || phoneNumber === "") {
        return false;
    }

    const regex = /^[0-9+\s]+$/;
    return regex.test(phoneNumber);
}

const validateAlphabetic = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[A-Za-z-'.\s]+$/;
    return regex.test(input);
}

const validateNumeric = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[0-9]+$/;
    return regex.test(input);
}

const validateAlphaNumeric = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[A-Za-z0-9\s]+$/;
    return regex.test(input);
}

export const validatePostcode = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[A-Za-z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}$/;
    return regex.test(input);
}

const validateInstructions = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[A-Za-z0-9'.,;!&*/"\s]+$/;
    return regex.test(input);
}

const validateAccountNumber = (input) => {
    const regex = /^\d{8}$/;
    return regex.test(input);
}

const validateSortCode = (input) => {
    const regex = /^\d{2}-\d{2}-\d{2}$/;
    return regex.test(input);
}

const validateProvider = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[A-Za-z0-9'\s]+$/;
    return regex.test(input);
}

const validateAddress = (input) => {
    if (!input || input === "") {
        return false;
    }

    const regex = /^[A-Za-z0-9\s,'()&-]+$/;
    return regex.test(input);
}

const validateEmail = (email) => {
    if (!email || email === "") {
        return false;
    }

    const regex = /^[^\s@]+@([a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return regex.test(email);
}

const validateReferral = (value) => {
    if (!value || value.trim() === "") {
        return false;
    }

    const regex = /^[A-Za-z0-9'.,;!&*/()"\s]+$/;
    return regex.test(value);
}

const validateReferralOption = (value) => {
    if (!value || value === "") {
        return false;
    }

    return referralsList.includes(value);
}