import React, { useState, useEffect, useRef } from 'react';
import { RangeSlider } from 'flowbite-react';

const Slider = ({ min, max, step, value, setValue }) => {
    const [valuePosition, setValuePosition] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        const sliderWidth = sliderRef.current.offsetWidth;
        const thumbWidth = 18;
        const newPosition = ((value - min) / (max - min)) * (sliderWidth - thumbWidth) + thumbWidth / 2;
        setValuePosition(newPosition);
    }, [value, min, max]);

    const handleChange = (e) => {
        setValue(parseInt(e.target.value, 10));
    };

    return (
        <div className="w-full p-4 relative">
            <div className="relative mt-4" ref={sliderRef}>
                <RangeSlider
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={handleChange}
                    className="w-full"
                />
                <div
                    className="absolute -top-6 text-md font-bold text-ms3-blue whitespace-nowrap"
                    style={{ left: `${valuePosition}px`, transform: 'translateX(-50%)' }}
                >
                    &pound;{value}
                </div>
            </div>
        </div>
    );
};

export default Slider;
