import { useNavigate } from 'react-router-dom';
import {
    PiNumberCircleOneFill,
    PiNumberCircleTwoFill,
    PiNumberCircleThreeFill,
    PiNumberCircleFourFill,
    PiNumberCircleFiveFill,
    PiNumberCircleSixFill,
    PiNumberCircleSevenFill
} from "react-icons/pi";
import { FaCheck } from "react-icons/fa";

const Steps = ({ steps, activeTab, setActiveTab }) => {
    const navigate = useNavigate();

    const iconComponents = [
        PiNumberCircleOneFill,
        PiNumberCircleTwoFill,
        PiNumberCircleThreeFill,
        PiNumberCircleFourFill,
        PiNumberCircleFiveFill,
        PiNumberCircleSixFill,
        PiNumberCircleSevenFill
    ];

    const icons = iconComponents.slice(0, steps.length);

    const handleTabClick = (tab, index) => {
        var previousStepsCompleted = true;
        for (var i = 0; i < index; i++) {
            previousStepsCompleted = previousStepsCompleted && steps[i].completed;
        }

        if (previousStepsCompleted) {
            navigate(`/${tab.tabName}`);
            setActiveTab(tab.tabName);
        }
    }

    return (
        <>
            <div className="md:visible hidden md:flex flex-row px-20 py-5 justify-between items-center border-b-2 border-ms3-pink shadow-xl">
                {steps.map((tab, index) => {
                    const IconComponent = tab.completed ? FaCheck : icons[index];

                    return (
                        <div key={index}
                            aria-disabled={!tab.completed}
                            onClick={() => handleTabClick(tab, index)}
                            className={`${activeTab === tab.tabName ? 'text-ms3-pink' : 'text-gray-500'} hover:text-ms3-pink text-lg flex flex-row items-center gap-1 cursor-pointer group`}
                        >
                            <IconComponent className={`${tab.completed && activeTab === tab.tabName ? 'bg-ms3-pink border-ms3-pink' : ''} ${tab.completed ? 'group-hover:bg-ms3-pink border-2 border-green-700 group-hover:border-ms3-pink rounded-full text-white bg-green-700 p-1 text-2xl' : 'text-3xl'}`} />
                            <div className='text-md font-bold'>{tab.tabName}</div>
                        </div>
                    );
                })}
            </div>

            <div className="md:hidden visible flex flex-row px-5 py-5 justify-between items-center border-b-2 border-ms3-pink shadow-xl">
                {steps.map((tab, index) => {
                    const IconComponent = tab.completed ? FaCheck : icons[index];

                    return (
                        <div key={index}
                            aria-disabled={!tab.completed}
                            onClick={() => handleTabClick(tab, index)}
                            className={`${activeTab === tab.tabName ? 'text-ms3-pink' : 'text-gray-500'} hover:text-ms3-pink text-lg flex flex-row items-center gap-1 cursor-pointer group`}
                        >
                            <IconComponent className={`${tab.completed && activeTab === tab.tabName ? 'bg-ms3-pink border-ms3-pink' : ''} ${tab.completed ? 'group-hover:bg-ms3-pink border-2 border-green-700 group-hover:border-ms3-pink rounded-full text-white bg-green-700 p-1 text-2xl' : 'text-3xl'}`} />
                            {activeTab === tab.tabName && (
                                <div className='text-md font-bold'>{tab.tabName}</div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default Steps;
