const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiConfig = {
  // INFINITY
  getOffers: {
    url: `${API_BASE_URL}/Offers`,
    method: 'GET',
  },

  // PORTAL
  searchAddresses: (postcode) => ({
    url: `${API_BASE_URL}/Availability/addresses/${postcode}`,
    method: 'GET',
  }),

  getAddressAvailability: (uprn) => ({
    url: `${API_BASE_URL}/Availability/${uprn}`,
    method: 'GET',
  }),

  generateOtp: {
    url: `${API_BASE_URL}/PhoneValidation/generateOtp`,
    method: 'POST',
  },

  getAvailableAppointments: {
    url: `${API_BASE_URL}/Appointments/availableAppointments`,
    method: 'POST',
  },

  confirmOtp: {
    url: `${API_BASE_URL}/PhoneValidation/confirmOtp`,
    method: 'POST',
  },

  placeOrder: {
    url: `${API_BASE_URL}/Order/placeOrder`,
    method: 'POST',
  },

  saveMetadata: {
    url: `${API_BASE_URL}/Offers/saveMetadata`,
    method: 'POST',
  },
};

export default apiConfig;
