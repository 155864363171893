import OfferOptionsBadge from './offerOptionsBadge';
import { FaWifi } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { FaWrench } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { PiTelevisionBold } from "react-icons/pi";
import { IoIosLock } from "react-icons/io";
import { FaInfinity } from "react-icons/fa6";

const OfferOptions = ({ offer, moreDetailsModal }) => {

    return (
        <div className={`${!moreDetailsModal ? 'md:w-3/4 md:justify-start justify-center' : 'justify-evenly'} flex flex-wrap gap-3 md:py-5 py-5 px-1`}>
            {offer.offerOptionsBadges.includes("Price Locked in Contract") && (
                <OfferOptionsBadge text="Price Locked in Contract">
                    <IoIosLock />
                </OfferOptionsBadge>
            )}

            {offer.offerOptionsBadges.includes("Unlimited Usage") && (
                <OfferOptionsBadge text="Unlimited Usage">
                    <FaInfinity />
                </OfferOptionsBadge>
            )}

            {(offer.packageType === 2 || offer.packageType === 3) && (
                <OfferOptionsBadge text="Phone Included">
                    <FaPhoneAlt />
                </OfferOptionsBadge>
            )}

            {offer.packageType === 3 && (
                <OfferOptionsBadge text="TV Package Included">
                    <PiTelevisionBold />
                </OfferOptionsBadge>
            )}

            {offer.offerOptionsBadges.includes("Free Standard Install") && (
                <OfferOptionsBadge text="Free Standard Install">
                    <FaWrench />
                </OfferOptionsBadge>
            )}

            {offer.offerOptionsBadges.includes("Free Ultrafast Router") && (
                <OfferOptionsBadge text="Free Ultrafast Router">
                    <FaWifi />
                </OfferOptionsBadge>
            )}

            {offer.offerOptionsBadges.includes("30 Day Satisfaction Guarantee") && (
                <OfferOptionsBadge text="30 Day Satisfaction Guarantee">
                    <FaThumbsUp />
                </OfferOptionsBadge>
            )}
        </div>
    )
}

export default OfferOptions;