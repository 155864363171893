const PromotedOfferBadge = ({ offer }) => {

    return (
        <>
            {offer.isPromoted && (
                <div className="absolute md:left-3/4 left-1/2 transform -translate-x-1/2 -top-2 p-1 bg-ms3-pink text-white md:text-sm text-xs rounded-full md:w-1/6 w-2/3 flex flex-row gap-1 items-center justify-center font-bold">
                    Promoted Package
                </div>
            )}
        </>
    )
}

export default PromotedOfferBadge;