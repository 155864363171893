const Rfs = ({ address }) => {

    return (
        <div className="my-10 relative">
            <img src="/rfs.png" className="rounded-2xl" alt="RFS" />

            <div className="absolute inset-0 flex flex-col md:justify-left justify-center md:gap-10 gap-1 pl-10">
                <div className="w-2/3 text-white md:text-4xl text-xs ">{address.fullAddress}</div>

                <div>
                    <div className="text-white md:text-7xl text-xl font-bold ">Is Ready For</div>
                    <div className="text-white md:text-7xl text-xl font-bold ">Ultrafast Broadband!</div>
                </div>

                <div>
                    <div className="text-white md:text-3xl text-xs">Use the filters below to find the best</div>
                    <div className="text-white md:text-3xl text-xs">package for your home!</div>
                </div>
            </div>
        </div>
    );
}

export default Rfs;