import { Card } from 'flowbite-react';
import Input from '../common/input/input';
import { useState } from 'react';
import { validateField } from '../../util/validation/validation';
import Ms3Button from '../common/button/button';

const Payment = ({ provider, paymentDetails, updatePaymentDetails, onSubmit }) => {

    const [errors, setErrors] = useState({
        accountHolder: false,
        sortCode: false,
        account: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        let formattedValue = value;

        if (name === 'sortCode') {
            formattedValue = value.replace(/[^0-9-]/g, '').replace(/(\-+)/g, '-');
            formattedValue = formattedValue.replace(/(\d{2})(?=\d)/g, '$1-');
        }

        if (name === 'account') {
            formattedValue = value.replace(/[^0-9]/g, '');
        }

        updatePaymentDetails({
            ...paymentDetails,
            [name]: formattedValue,
        });

        validateFields(name, value);
    };

    const validateFields = (name, value) => {
        var valid = validateField(name, value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !valid,
        }));

        return valid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var hasErrors;
        const requiredFields = ['accountHolder', 'sortCode', 'account'];
        requiredFields.forEach(field => {
            if (!validateFields(field, paymentDetails[field])) {
                hasErrors = true;
            }
        });

        if (!hasErrors) {
            onSubmit();
        }
    };

    return (
        <div className="md:my-20 my-5 flex flex-col items-center md:mx-0 mx-3">
            <Card className="md:max-w-3xl max-w-md md:w-3/5 md:p-10 p-3 shadow-ms3-blue-xl border-0">
                <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    <div className="md:flex flex-col justify-between gap-5">

                        <div className="text-md mb-5 text-ms3-blue">
                            {`We'll pass your details securely to ${provider ? provider : 'your provider'}. No payment will be taken yet.`}
                        </div>

                        <Input
                            className="mt-10"
                            name="accountHolder"
                            label="Full name of account holder"
                            placeholder="Name"
                            value={paymentDetails.accountHolder}
                            onChange={handleChange}
                            invalid={errors.accountHolder} />

                        <Input
                            className="mt-10"
                            name="sortCode"
                            label="Enter the sort code"
                            placeholder="Sort Code"
                            value={paymentDetails.sortCode}
                            onChange={handleChange}
                            invalid={errors.sortCode}
                            error="Sort Code must have 6 digits." />

                        <Input
                            className="mt-10"
                            name="account"
                            label="Enter the account number"
                            placeholder="Account"
                            value={paymentDetails.account}
                            onChange={handleChange}
                            invalid={errors.account}
                            error="Account must have 8 digits." />

                        <div className="text-md mb-10 flex flex-row gap-5 items-center text-ms3-blue">
                            <img src="/dd_logo.png" className="md:h-8 h-6" alt="Direct Debit" />
                            <div>
                                Payments are protected by the <a className="underline" href="https://www.directdebit.co.uk/" target='_blank' rel="noreferrer">Direct Debit Guarantee</a>.
                            </div>
                        </div>
                    </div>

                    <Ms3Button text="Continue to confirmation" />
                </form>
            </Card>
        </div>
    );
}

export default Payment;
