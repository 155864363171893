const TrustScore = ({ offer, moreDetailsModal }) => {

  const url = "https://uk.trustpilot.com/review/" + offer.trustPilotScore.url;
  const rating = offer.trustPilotScore.score;
  const source = "//images-static.trustpilot.com/api/stars/" + offer.trustPilotScore.stars + "/128x24.png";

  const textColor = moreDetailsModal
    ? 'text-black'
    : (offer.isPromoted ? "text-white" : 'text-black');

  return (
    <a href={url} target='_blank' rel="noreferrer" className='flex flex-col gap-2 font-normal items-center'>
      <img src={source} alt="TrustPilot" className="md:w-full w-3/4"/>

      <div className='flex flex-col'>
        <div className={`md:text-sm text-xs ${textColor}`}>
          TrustScore <span className="font-bold">{rating.toFixed(1)}</span>
        </div>
        <div className={`text-xs ${textColor}`}>
          <span className="font-bold">{offer.trustPilotScore.numberOfReviews}</span> reviews
        </div>
      </div>
    </a>
  );
};

export default TrustScore;
